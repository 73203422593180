@media (min-width: 992px) {
    .interactive-section {
        padding-bottom: 0;
        height: 430px;
        /*margin-top: 40px;*/
    }
}

@media (max-width: 992px) {
    .interactive-section {
        padding-bottom: 0;
        height: 15rem;
        padding-top: 10px;
    }

    img.screen-interactive.img-fluid {
        width: 94%;
    }
}

@media (max-width: 496px) {

    img.screen-interactive.img-fluid {
        width: 92%;
    }
}

.screen-interactive {
    -webkit-box-shadow: 0px 8px 57px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 8px 57px -8px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 8px 57px -8px rgba(0, 0, 0, 0.75);

    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.floating-flag-container {
    background: url('../img/screen_codeflag.png');
    height: 300px;
    padding-top: 70px;
}


.floating-activity-container {
    background: url('../img/screen_activity_feed.png');

}


.img-codeflag-preview-popup {
    position: absolute;
    top: -120px;
    -webkit-box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.75);
}


.img-codeflag-cropped {
    cursor: pointer;
}

.text-hint {
    text-align: center;
    display: block;
    margin: 60px auto 0px;
}

.img-console {
    margin-bottom: 30px;
    /*position: absolute;*/
    left: 0;
}

.footer.has-cards:before {
    background: none;
}


.section-profile-cover {
    /*height: 50px;*/
}


.pricing-titles-container.container {
    margin-top: 10rem;
}


.interactive-section-bubble {
    background: rgba(255, 255, 255, 0.3);
    width: 3.5rem;
    height: 3.5rem;
    line-height: 3.5rem;
}

a#flag-bubble {
    left: 10rem;
    top: 12rem;

    background: rgba(255, 255, 255, 0.3);
    width: 3.5rem;
    height: 3.5rem;
    line-height: 3.5rem;
}


a#people-bubble {
    left: 62.5%;
    top: 9rem;
    background: rgba(255, 255, 255, 0.3);
    width: 3.5rem;
    height: 3.5rem;
    line-height: 3.5rem;
}

a#feed-bubble {
    left: 56.3%;
    top: 17rem;

    background: rgba(255, 255, 255, 0.3);
    width: 3.5rem;
    height: 3.5rem;
    line-height: 3.5rem;
}


.section-shaped .shape-default :nth-child(1) {
    background: transparent;
}


@media (max-width: 992px) {
    .interactive-section-bubble{
        display: none;
    }
}
